
import { computed, defineComponent, onMounted, ref } from "vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { EcosystemicService } from "@/store/modules/DiagnosticsModule";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import EcosystemicServicesFactorsJustifications from "@/components/diagnostic/EcosystemicServicesFactorsJustifications.vue";

export default defineComponent({
  name: "ComputingMethodEcosystemicServices",
  components: {
    CardTitleJaya,
    Datatable,
    EcosystemicServicesFactorsJustifications,
  },
  setup(props) {
    const store = useStore();

    let ecosystemicServices = ref<Array<EcosystemicService>>([]);

    const factors = computed(() => store.getters.getFactors);

    const tableHeader = ref([
      {
        name: "Facteur",
        key: "factor",
        sortable: false,
      },
      {
        name: "Niveau",
        key: "level",
        sortable: false,
      },
      {
        name: "Lien avec le service ?",
        key: "link",
        sortable: false,
      },
      {
        name: "Justification",
        key: "justification",
        sortable: false,
      },
      {
        name: "Point",
        key: "point",
        sortable: false,
      },
    ]);

    const tableData = ref([
      {
        factor: "F1-5",
        level: "0",
        link: "Non",
        justification: "Niveau 0",
        point: 0,
      },
      {
        factor: "F1-5",
        level: "1",
        link: "Non",
        justification:
          "Ce niveau ne participe pas à la fourniture su service concerné",
        point: 0,
      },
      {
        factor: "F1-5",
        level: "2",
        link: "Non",
        justification:
          "Ce niveau ne participe pas à la fourniture su service concerné",
        point: 0,
      },
      {
        factor: "F1-5",
        level: "3",
        link: "Oui",
        justification:
          "Les bandes enherbées apportent une protection du sol par rapport à un sol nu",
        point: 1,
      },
      {
        factor: "F1-5",
        level: "4",
        link: "Oui",
        justification:
          "Les bandes enherbées apportent une protection du sol par rapport à un sol nu",
        point: 1,
      },
      {
        factor: "F1-5",
        level: "5",
        link: "Non",
        justification:
          "Ce niveau ne participe pas à la fourniture su service concerné",
        point: 0,
      },
    ]);

    onMounted(() => {
      store.dispatch(Actions.FETCH_ECOSYSTEMIC_SERVICES_CATEGORIES).then(() => {
        setCurrentPageTitle("Services Ecosystémiques");

        store.getters.getEcosystemicServicesCategories.forEach((eSC) => {
          ecosystemicServices.value = ecosystemicServices.value.concat(
            eSC.services
          );
        });
      });
      store.dispatch(Actions.FETCH_QUIZ);
    });

    return {
      tableHeader,
      tableData,
      factors,
      ecosystemicServices,
    };
  },
});
