
import { computed, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ecosystemicServices from "@/views/diagnostic/EcosystemicServices.vue";

export default {
  name: "EcosystemicServicesFactorsJustifications",
  props: {
    ecosystemicServices: { type: Array, required: true },
    factors: { type: Array, required: true },
  },
  setup(props) {
    let chartFactorDirectionX = ref<boolean>(
      props.ecosystemicServices.length === 1
    );
    let chartOptions = computed(() => {
      return {
        chart: {
          type: "heatmap",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          selection: {
            enabled: false,
          },
          height: 90 + 30 * props.ecosystemicServices.length,
        },
        series: chartFactorDirectionX.value
          ? props.ecosystemicServices.map((eS) => {
              return {
                name: eS.short_name,
                data: props.factors.map((f) => {
                  const factorLevelsES = f.levels.map((l) => {
                    return eS.factor_levels.find(
                      (fl) => fl.factor_level.id === l.id
                    );
                  });
                  return factorLevelsES.filter((flES) => {
                    if (flES) {
                      return flES.active;
                    } else {
                      return false;
                    }
                  }).length;
                }),
              };
            })
          : props.factors.map((f) => {
              return {
                name: f.number,
                data: props.ecosystemicServices.map((eS) => {
                  const factorLevelsES = f.levels.map((l) => {
                    return eS.factor_levels.find(
                      (fl) => fl.factor_level.id === l.id
                    );
                  });
                  return factorLevelsES.filter((flES) => {
                    if (flES) {
                      return flES.active;
                    } else {
                      return false;
                    }
                  }).length;
                }),
              };
            }),
        colors: [getCSSVariableValue("--bs-primary")],
        plotOptions: {
          heatmap: {
            inverse: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: chartFactorDirectionX.value
          ? props.factors.map((f) => {
              return f.number;
            })
          : props.ecosystemicServices.map((eS) => eS.short_name),
        yaxis: {
          labels: {
            show: props.ecosystemicServices.length > 1,
          },
        },
        xaxis: {
          labels: {
            rotate: -90,
          },
        },
        tooltip: {
          enabled: true,
          x: {
            enabled: false,
          },
          y: {
            formatter: function (
              value,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              return (
                "<div class='w-150px text-wrap'>" +
                "Service écosystémique : <br><span class='fs-5 fst-italic fw-normal'>" +
                props.ecosystemicServices[
                  chartFactorDirectionX.value ? seriesIndex : dataPointIndex
                ].name +
                "</span><br>" +
                "Facteur : <br><span class='fs-5 fst-italic fw-normal'>" +
                props.factors[
                  chartFactorDirectionX.value ? dataPointIndex : seriesIndex
                ].title +
                "</span></div>"
              );
            },
            title: {
              formatter: () => "",
            },
          },
          marker: {
            show: false,
          },
        },
      };
    });

    const onDataPointSelection = (event, chartContext, config) => {
      const factor =
        props.factors[
          chartFactorDirectionX.value
            ? config.dataPointIndex
            : config.seriesIndex
        ];
      const ecosystemicService =
        props.ecosystemicServices[
          chartFactorDirectionX.value
            ? config.seriesIndex
            : config.dataPointIndex
        ];

      let content = "<div>";
      factor.levels
        .map((f_l) => {
          return ecosystemicService.factor_levels.find(
            (e) => e.factor_level.id === f_l.id
          );
        })
        .sort((a, b) => (a.factor_level.level < b.factor_level.level ? -1 : 1))
        .forEach((e) => {
          if (e) {
            content +=
              "<div class='mb-1'>" +
              (e.active
                ? '<i class="bi text-primary bi-check-circle fs-4 me-1"></i>'
                : '<i class="bi text-danger bi-x-circle fs-4 me-1"></i>') +
              "<strong>Niveau " +
              e.factor_level.level +
              " : </strong>" +
              e.justification +
              "</div>";
          }
        });

      content += "</div>";

      Swal.fire({
        title:
          "<div><span class='fst-italic fw-normal'>Influence du facteur <br /></span>'" +
          factor.title +
          "'<br /><span class='fst-italic fw-normal'>sur le service écosystémique <br /></span>'" +
          ecosystemicService.name +
          "'</div>",
        html: content,
        showCloseButton: true,
        showConfirmButton: false,
      });
    };

    return {
      chartOptions,
      onDataPointSelection,
    };
  },
};
